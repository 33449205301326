import { isAddressFormat } from '@/app/utils/helper'
import { SearchStatusType } from '@/types/enum'

export default defineNuxtRouteMiddleware((to) => {
  const appStore = useAppStore()

  if (to.name === 'contract-contract') {
    if (!to.params.contract) {
      appStore.$patch({
        searchStatus: SearchStatusType.ContractInvalid,
        searchParam: to.params.contract
      })

      return navigateTo('/404')
    }

    const routeContractParam = Array.isArray(to.params.contract)
      ? to.params.contract[0]
      : to.params.contract

    if (!isAddressFormat(routeContractParam)) {
      appStore.$patch({
        searchStatus: SearchStatusType.ContractInvalid,
        searchParam: routeContractParam
      })

      return navigateTo('/404')
    }
  }
})
